<template>
    <div class="container">
        <div class="text-center card mt-7">
            <Avatar class="bg-pink-500 text-white" size="large" icon="pi pi-ban" />   
            <div class="text-2xl my-3  font-bold text-pink-500 my-3">
                Your account has been blocked
            </div>
            <div class="text-primary mb-3">
                Please contact the system administrator for more information
            </div>
            <Divider></Divider>
                <div class="text-center">
                    <router-link :to="`/index/login`">
                        <Button label="Continue" />
                    </router-link>
                </div>
        </div>
    </div>
</template>
